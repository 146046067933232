import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
        services: [
            {name: 'A Empresa', icon: '', iconDark: ''},
            {name: 'Redes lógicas', icon: require('@/assets/Icons/SVG/rede_logica.svg'), iconDark: ''},
            {name: 'SDAI', icon: require('@/assets/Icons/SVG/incendio.svg'), iconDark: ''},
            {name: 'Alarme de Segurança', icon: '', iconDark: ''},
            {name: 'Sonorização', icon: '', iconDark: ''},
            {name: 'CFTV', icon: '', iconDark: ''},
        ],
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
