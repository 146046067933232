<template>
    <div :style="overlay ? 'overflow: hidden;height: 100vh;' : ''">
    <v-app>
        <router-view />
        <div v-show="overlay" class="loading">
            <div class="breeding-rhombus-spinner">
                <div class="rhombus child-1"></div>
                <div class="rhombus child-2"></div>
                <div class="rhombus child-3"></div>
                <div class="rhombus child-4"></div>
                <div class="rhombus child-5"></div>
                <div class="rhombus child-6"></div>
                <div class="rhombus child-7"></div>
                <div class="rhombus child-8"></div>
                <div class="rhombus big"></div>
            </div>
        </div>
    </v-app>
    </div>
</template>

<script>
export default {
    data() {
        return {
            overlay: true
        }
    },
    mounted(){
        setTimeout(() => {
            //be executed after 1 second
            this.overlay = false
        }, 1500);
        (function titleScroller(text) {
            document.title = text;
            setTimeout(function () {
                titleScroller(text.substr(1) + text.substr(0, 1));
            }, 500);
        }(" Geotech Tecnologia, há mais de 15 anos no mercado. "));
    }
}
</script>

<style lang="scss">
body{
    background-color: black
}
.loading{
    background-color: #000F3F;
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: 10;

    display: flex;
    justify-content: center;
    align-items: center;
}
.breeding-rhombus-spinner {
      height: 65px;
      width: 65px;
      position: relative;
      transform: rotate(45deg);
    }

    .breeding-rhombus-spinner, .breeding-rhombus-spinner * {
      box-sizing: border-box;
    }

    .breeding-rhombus-spinner .rhombus {
      height: calc(65px / 7.5);
      width: calc(65px / 7.5);
      animation-duration: 2000ms;
      top: calc(65px / 2.3077);
      left: calc(65px / 2.3077);
      background-color: white;
      position: absolute;
      animation-iteration-count: infinite;
    }

    .breeding-rhombus-spinner .rhombus:nth-child(2n+0) {
       margin-right: 0;
     }

    .breeding-rhombus-spinner .rhombus.child-1 {
      animation-name: breeding-rhombus-spinner-animation-child-1;
      animation-delay: calc(100ms * 1);
    }

    .breeding-rhombus-spinner .rhombus.child-2 {
      animation-name: breeding-rhombus-spinner-animation-child-2;
      animation-delay: calc(100ms * 2);
    }

    .breeding-rhombus-spinner .rhombus.child-3 {
      animation-name: breeding-rhombus-spinner-animation-child-3;
      animation-delay: calc(100ms * 3);
    }

    .breeding-rhombus-spinner .rhombus.child-4 {
      animation-name: breeding-rhombus-spinner-animation-child-4;
      animation-delay: calc(100ms * 4);
    }

    .breeding-rhombus-spinner .rhombus.child-5 {
      animation-name: breeding-rhombus-spinner-animation-child-5;
      animation-delay: calc(100ms * 5);
    }

    .breeding-rhombus-spinner .rhombus.child-6 {
      animation-name: breeding-rhombus-spinner-animation-child-6;
      animation-delay: calc(100ms * 6);
    }

    .breeding-rhombus-spinner .rhombus.child-7 {
      animation-name: breeding-rhombus-spinner-animation-child-7;
      animation-delay: calc(100ms * 7);
    }

    .breeding-rhombus-spinner .rhombus.child-8 {
      animation-name: breeding-rhombus-spinner-animation-child-8;
      animation-delay: calc(100ms * 8);
    }

    .breeding-rhombus-spinner .rhombus.big {
      height: calc(65px / 3);
      width: calc(65px / 3);
      animation-duration: 2000ms;
      top: calc(65px / 3);
      left: calc(65px / 3);
      background-color: white;
      animation: breeding-rhombus-spinner-animation-child-big 2s infinite;
      animation-delay: 0.5s;
    }


    @keyframes breeding-rhombus-spinner-animation-child-1 {
      50% {
        transform: translate(-325%, -325%);
      }
    }

    @keyframes breeding-rhombus-spinner-animation-child-2 {
      50% {
        transform: translate(0, -325%);
      }
    }

    @keyframes breeding-rhombus-spinner-animation-child-3 {
      50% {
        transform: translate(325%, -325%);
      }
    }

    @keyframes breeding-rhombus-spinner-animation-child-4 {
      50% {
        transform: translate(325%, 0);
      }
    }

    @keyframes breeding-rhombus-spinner-animation-child-5 {
      50% {
        transform: translate(325%, 325%);
      }
    }

    @keyframes breeding-rhombus-spinner-animation-child-6 {
      50% {
        transform: translate(0, 325%);
      }
    }

    @keyframes breeding-rhombus-spinner-animation-child-7 {
      50% {
        transform: translate(-325%, 325%);
      }
    }

    @keyframes breeding-rhombus-spinner-animation-child-8 {
      50% {
        transform: translate(-325%, 0);
      }
    }

    @keyframes breeding-rhombus-spinner-animation-child-big {
      50% {
        transform: scale(0.5);
      }
    }

    // Fonts

    // @font-face {
    // font-family: 'Teko Regular';
    // font-style: normal;
    // font-weight: normal;
    // src: local('Teko Regular'), url('./fonts/Teko-Regular.woff') format('woff');
    // }
    

    // @font-face {
    // font-family: 'Teko Light';
    // font-style: normal;
    // font-weight: normal;
    // src: local('Teko Light'), url('./fonts/Teko-Light.woff') format('woff');
    // }
    

    @font-face {
    font-family: 'Teko Medium';
    font-style: normal;
    font-weight: normal;
    src: local('Teko Medium'), url('./fonts/Teko-Medium.woff') format('woff');
    }
    

    // @font-face {
    // font-family: 'Teko SemiBold';
    // font-style: normal;
    // font-weight: normal;
    // src: local('Teko SemiBold'), url('./fonts/Teko-SemiBold.woff') format('woff');
    // }
    

    // @font-face {
    // font-family: 'Teko Bold';
    // font-style: normal;
    // font-weight: normal;
    // src: local('Teko Bold'), url('./fonts/Teko-Bold.woff') format('woff');
    // }

    
    @font-face {
        font-family: 'Product Sans';
        src: local('Open Sans'), local('OpenSans'), url(https://fonts.gstatic.com/s/productsans/v5/HYvgU2fE2nRJvZ5JFAumwegdm0LZdjqr5-oayXSOefg.woff2) format('woff2');
    }

    $body-font-family: 'Product Sans';
    $title-font: 'Teko Bold';

    .v-application {
        font-family: $body-font-family, sans-serif !important;
        .title { // To pin point specific classes of some components
        font-family: $title-font, sans-serif !important;
        }
    }
</style>
