<template>
  <div id="Footer">
    <v-footer
      dark
      padless
      style="max-width: 100%!important; overflow: hidden!important;"
    >
      <v-card flat tile width="100%" color="third" class="py-14 text-center">
        <v-container>
          <v-row>
            <v-col cols="12" lg="4">
              <v-card flat color="third">
                <h3
                  class="text-left py-4 font-weight-light"
                  style="word-break: break-word!important; color: gold;"
                >
                  <span>GEOTECH TECNOLOGIA EIRELI</span> <br />
                  <span>CNPJ: 07.622.068/0001-51</span>
                </h3>
                <h4 class="text-left font-weight-light mb-5">
                  <span>Rua Carlos Gomes, Nº 261, Qd.20, Lt.19,</span>
                  <span>Parque Anhanguera, Goiânia - GO, CEP: 74.340-050</span>
                </h4>
                <div class="text-left mb-5">
                  <v-btn
                    v-for="(icon, i) in icons"
                    :key="i"
                    class="white--text"
                    :onclick="`window.open('${icon[1]}', '_blank');`"
                    icon
                  >
                    <v-icon size="24px">
                      {{ icon[0] }}
                    </v-icon>
                  </v-btn>
                </div>
              </v-card>
            </v-col>
            <v-col cols="12" lg="4" class="py-8 text-left">
              WhatsApp: (62)3092-1626 <br />
              E-mail: atendimento@geotech-go.com.br <br />
              © Copyright {{ new Date().getFullYear() }} <br />
            </v-col>
            <v-col cols="12" lg="4">
                <v-row class="pa-5" :justify="!$vuetify.breakpoint.mobile ? 'end' : 'center'">
                    <div>
                        <v-img max-width="350px" max-height="350px" src="@/assets/Icons/badge/selo-15-anos/SELODE15ANOS-GEOTECH.png" lazySrc="@/assets/Icons/badge/selo-15-anos/SELODE15ANOS-GEOTECH3X.png"></v-img>
                    </div>
                </v-row>
            </v-col>
          </v-row>
        </v-container>

        <v-divider></v-divider>

        <v-card-text class="white--text">
          {{ new Date().getFullYear() }} — <strong>Geotech</strong>
        </v-card-text>
      </v-card>
    </v-footer>
  </div>
</template>

<script>
export default {
  data: () => ({
    icons: [
      ["mdi-facebook", "https://www.facebook.com/GeotechTecnologia"],
      [
        "mdi-instagram",
        "https://www.instagram.com/geotechtecnologia/?hl=pt-br",
      ],
    ],
  }),
};
</script>

<style></style>
