<template>
    <layout>
        <!-- <Slider /> -->
        <Overlay />
        <Parceiros />
        <Cards />
        <Quem-somos />
        <Obras />
        <POPUPS />
        <Footer />
    </layout>
</template>

<script>
import layout from '@/components/Layout.vue'
//import Slider from '@/components/homepage/Slider.vue'
import Cards from '@/components/homepage/Cards.vue'
import Overlay from '@/components/homepage/Overlay.vue'
import POPUPS from '@/components/homepage/POPUPS.vue'
import Parceiros from '@/components/homepage/Parceiros.vue'
import Obras from '@/components/homepage/Obras.vue'
import QuemSomos from '@/components/homepage/QuemSomos.vue'
import Footer from '@/components/Footer.vue'
export default {
    components: {
        layout, Cards, Overlay, POPUPS, Parceiros, QuemSomos, Obras, Footer
        //Slider
    }
}
</script>

<style>

</style>