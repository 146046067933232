<template>
          <vueper-slides
            class="no-shadow logos mb-0"
            :visible-slides="numero"
            :arrows="false"
            :fixed-height="$vuetify.breakpoint.mobile ? '50px':'150px'"
            fixed-width="250px"
            autoplay
            :gap="3"
            :dragging-distance="70"
            :bullets="false">
            <vueper-slide v-for="i in listaLogos" :key="i" :image="i"></vueper-slide>
        </vueper-slides>
</template>

<script>
    import { VueperSlides, VueperSlide } from 'vueperslides'
    import 'vueperslides/dist/vueperslides.css'
    export default {
        components: {VueperSlides, VueperSlide},
        props: {
            listaLogos: {
                type: Array
            },
            numero: {
                type: Number
            }
        }
    }
</script>

<style lang="scss">
    .logos{
        .vueperslide{
            background-size: contain;

            &:hover{
                filter: none;
            }
        }
    }
</style>