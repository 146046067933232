import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

const vuetify = new Vuetify({
    theme: {
      themes: {
        light: {
            primary: '#000',
            secondary: '#034694',
            third: '#2D4B7D',
            anchor: '#8c9eff',
            brilliant: '#002ECC',
            background: '#fff',
            grayscale: '#E6E6E6',
        },
        dark: {
            primary: '#fff',
            background: '#002A44',
        }
      },
    },
  })
  
export default vuetify
