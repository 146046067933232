<template>
    <div class="certificates">
        <v-card color="transparent" style="pointer-events:auto;" class="rounded-0" flat>
            <v-container>
                <vueper-slides   
                class="no-shadow mt-10"
                :arrows="false"
                :bullets="false"
                :visible-slides="$vuetify.breakpoint.mobile ? 3 : 4"
                autoplay
                :gap="2"
                fixed-height="150px"
                fixed-width="150px">
                    <vueper-slide v-for="(slide, i) in slides" :key="i" @click.native="clique(i)" :image="slide[0]"></vueper-slide>
                </vueper-slides>
                <v-dialog transition="dialog-bottom-transition" max-width="1000" :value="overlay">
                    <v-card color="primary">
                        <v-row class="ma-0 pa-0">
                            <v-col class="pa-0" cols="12" lg="12">
                                <v-carousel v-model="slideNum">
                                    <v-carousel-item
                                    v-for="(slide, i) in slides"
                                    :key="i"
                                    :src="slide[1]"
                                    :aspect-ratio="1/1"
                                    contain
                                    >
                                    </v-carousel-item>
                                </v-carousel>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-dialog>
            </v-container>
        </v-card>
    </div>
</template>

<script>
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'
export default{
    components: { VueperSlides, VueperSlide },
    data: () => ({
        slides: [
            [require('@/assets/certificates/6.jpg'), require('@/assets/certificates/6.jpg')],
            [require('@/assets/certificates/1.jpg'), require('@/assets/certificates/1.jpg')],
            [require('@/assets/certificates/2.jpg'), require('@/assets/certificates/2.jpg')],
            [require('@/assets/certificates/3.jpg'), require('@/assets/certificates/3.jpg')],
            [require('@/assets/certificates/4.jpg'), require('@/assets/certificates/4.jpg')],
            [require('@/assets/certificates/5.jpg'), require('@/assets/certificates/5.jpg')],
        ],
        overlay: false,
        slideNum: 0,
    }),
    methods:{
        clique(v){
            this.overlay = !this.overlay
            this.slideNum = v
        }
    }
}
</script>

<style lang="scss">
    .certificates {
        .vueperslide{
            background-size: contain;
        }
    }
</style>