var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"rounded-0 py-10",attrs:{"color":"white","flat":""}},[_c('div',{staticClass:"quemsomos"},[_c('Particles',{staticClass:"pb-0",attrs:{"id":"QuemSomos","options":{
            background: {
                color: {
                    value: '#2D4B7D'
                }
            },
            fpsLimit: 40,
            interactivity: {
                detectsOn: 'canvas',
                events: {
                    onClick: {
                        enable: true,
                        mode: 'push'
                    },
                    onHover: {
                        enable: true,
                        mode: 'repulse'
                    },
                    resize: true
                },
                modes: {
                    bubble: {
                        distance: 400,
                        duration: 2,
                        opacity: 0.8,
                        size: 40
                    },
                    push: {
                        quantity: 4
                    },
                    repulse: {
                        distance: 200,
                        duration: 0.4
                    }
                }
            },
            particles: {
                color: {
                    value: '#63C3D4'
                },
                links: {
                    color: '#63C3D4',
                    distance: 150,
                    enable: true,
                    opacity: 0.5,
                    width: 1
                },
                collisions: {
                    enable: true
                },
                move: {
                    direction: 'none',
                    enable: true,
                    outMode: 'bounce',
                    random: false,
                    speed: 2,
                    straight: false
                },
                number: {
                    density: {
                        enable: true,
                        value_area: 800
                    },
                    value: 80
                },
                opacity: {
                    value: 0.5
                },
                shape: {
                    type: 'circle'
                },
                size: {
                    random: true,
                    value: 5
                }
            },
            detectRetina: true
        }}}),_c('div',{staticClass:"shade"}),_c('div',{staticClass:"overlay"},[_c('v-container',{staticStyle:{"height":"100%"}},[_c('v-row',{staticStyle:{"height":"100%"},attrs:{"align":"center","justify":"center"}},[_c('v-col',{staticClass:"texto-cta-overlay",attrs:{"cols":"12","lg":"8"}},[_c('div',{staticClass:"d-flex justify-center headers mb-n5"},[_c('div',[_c('h3',{staticClass:"text-h3 title",staticStyle:{"font-family":"Teko Medium!important"}},[_vm._v("QUEM SOMOS")]),_c('div')])]),_vm._v(" Somos uma empresa de Engenharia premiada, fundada em 2005. Há mais de 15 anos realizamos obras públicas e privadas no Centro-Oeste e em outras regiões do país. Atendemos do desenvolvimento de projetos até a execução dos serviços dentro do perfil de atuação da nossa empresa. Dispomos de uma equipe capacitada e qualificada pelos fabricantes. "),_c('Certificados')],1)],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }